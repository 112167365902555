@import '../tdi/css/lib';

@import 'main.css';
@import '_form.scss';
@import '_override.scss';

@import '../../../../../cmscustom/Q-Park/fonts/Futura/Futura.scss';

a {cursor:pointer;}
strong {font-weight:bold;}
em {font-style:italic;}
sup, sub {position: relative;top: -0.4em;vertical-align: baseline;}
sub { top: 0.4em; }
tr {height:20px;}
section img {max-width:100%;height:auto;}

.marginbottom- {
  &none { margin-bottom:0; }
}
span.highlight {
  background-color:#d6ecff;
}

table.standard {
  // horizontal alignment
  th, td {text-align: right;}
  th:first-child, td:first-child {text-align: left;}
  th.notes, td.notes {text-align: center;}
  // explicit classes overrides defaults
  th, td {
    &.text-center {text-align: center;}
    &.text-right {text-align: right;}
    &.text-left {text-align: left;}
  }

  // vertical alignment
  th {vertical-align: bottom;}
  td {vertical-align: top;}
  // explicit classes overrides defaults
  th, td {
    &.top {vertical-align: top;}
    &.bottom {vertical-align: bottom;}
    &.middle {vertical-align: middle;}
  }
  td.rotate-cw, th.rotate-cw {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    text-align: right;
  }
  td.rotate-ccw, th.rotate-ccw {
    writing-mode: vertical-rl;
    text-align: right;
  }
}

@each $list-style in (
  none, square, circle, disc,                                                       // ul list styles
  decimal, decimal-leading-zero, lower-alpha, upper-alpha, lower-roman, upper-roman // ol list-styles
) {
  .list-style-#{$list-style} {
    list-style: $list-style;
  }
}

.search {
  .search-result {
    padding-bottom:10px;
    h3 {
      margin-bottom:5px;
    }
    .search-result-snippet {
      .snippet-searchresult-highlight {
        font-weight: bold;
      }
    }
  }
}

.socialBlock {
  margin-top: 20px;
  text-align: right;
}

.socialBlock__title {
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
}

.text-subtle {
  color: #515151;
}

.socialBlock__links {
  display: inline-block;
}

.socialBlock a {
  display: inline-block;
  margin: 0;
  font-size: 16px;

  img {
    margin-bottom: 0;
  }
}