div.default.success,div.default.error {
	display:none;
}
div.default.form  div.default.success {
	color: #d90327;
	margin-top: 50px;
}
.formfield input,
.formfield textarea {
  width: 50%;
}
.formfield input[type="checkbox"]  {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -2px 6px 3px 0;
  vertical-align: middle;
  background: url(../img/check-bg.gif) 0 0 no-repeat;
  cursor: pointer; }
.formfield input[type="radio"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -2px 6px 3px 0;
  vertical-align: middle;
  background: url(../img/radio-bg.gif) 0 0 no-repeat;
  cursor: pointer; }
  
@media screen and (max-width: 991px) {
  .formfield input,
  .formfield textarea {
    width: 100%;
  }
}